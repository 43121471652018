/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://me6lcucwtfhulb63mzbrqdtnla.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hnoh2d356jaxjfyumdg27m26ki",
    "aws_cognito_identity_pool_id": "eu-north-1:0d46df00-aa42-4f6e-8658-bd304b649e92",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_ICCfwbucX",
    "aws_user_pools_web_client_id": "34t4a423ptmdtfvut4koi0c5k3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyreactgraphql6f2aaa919c9d4dc9a8f50c5f3d73142055-staging",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
