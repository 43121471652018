import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  Image,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { listNotes, listProjects } from "./graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
  createProject as createProjectMutation,
  deleteProject as deleteProjectMutation,
} from "./graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { uploadData, getUrl, remove } from "aws-amplify/storage";

const client = generateClient();

const App = ({ signOut }) => {
  const [notes, setNotes] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchNotes();
    fetchProjects();
  }, []);

  async function fetchNotes() {
    const apiData = await client.graphql({ query: listNotes });
    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(
      notesFromAPI.map(async (note) => {
        if (note.image) {
          const url = await getUrl({ key: note.name });
          note.image = url.url;
        }
        return note;
      })
    );
    setNotes(notesFromAPI);
  }
  async function fetchProjects() {
    const apiData = await client.graphql({ query: listProjects });
    const projectsFromAPI = apiData.data.listProjects.items;
    await Promise.all(
      projectsFromAPI.map(async (note) => {
        if (note.image) {
          const url = await getUrl({ key: note.name });
          note.image = url.url;
        }
        return note;
      })
    );
    setProjects(projectsFromAPI);
  }

  async function createNote(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      image: image.name,
    };
    if (!!data.image)
      await uploadData({
        key: data.name,
        data: image,
      });
    await client.graphql({
      query: createNoteMutation,
      variables: { input: data },
    });
    fetchNotes();
    event.target.reset();
  }

  async function createProject(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      image: image.name,
    };
    if (!!data.image)
      await uploadData({
        key: data.name,
        data: image,
      });
    await client.graphql({
      query: createProjectMutation,
      variables: { input: data },
    });
    fetchProjects();
    event.target.reset();
  }

  async function deletNote({ id, name }) {
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
    await remove({ key: name });
    await client.graphql({
      query: deleteNoteMutation,
      variables: { input: { id } },
    });
  }

  async function deleteProject({ id, name }) {
    const newProjects = notes.filter((note) => note.id !== id);
    setProjects(newProjects);
    await remove({ key: name });
    await client.graphql({
      query: deleteProjectMutation,
      variables: { input: { id } },
    });
  }

  return (
    <View className='App'>
      <div id='notes'>
        <Heading level={1}>My Notes App</Heading>
        <View as='form' margin='3rem 0' onSubmit={createNote}>
          <Flex direction='row' justifyContent='center'>
            <TextField
              name='name'
              placeholder='Note Name'
              label='Note Name'
              labelHidden
              variation='quiet'
              required
            />
            <TextField
              name='description'
              placeholder='Note Description'
              label='Note Description'
              labelHidden
              variation='quiet'
              required
            />
            <View
              name='image'
              as='input'
              type='file'
              style={{ alignSelf: "end" }}
            />
            <Button type='submit' variation='primary'>
              Create Note
            </Button>
          </Flex>
        </View>
        <Heading level={2}>Current Notes</Heading>
        <View margin='3rem 0'>
          {notes.map((note) => (
            <Flex
              key={note.id || note.name}
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Text as='strong' fontWeight={700}>
                {note.name}
              </Text>
              <Text as='span'>{note.description}</Text>
              {note.image && (
                <Image
                  src={note.image}
                  alt={`visual aid for ${notes.name}`}
                  style={{ width: 400 }}
                />
              )}
              <Button variation='link' onClick={() => deleteProject(note)}>
                Delete 1 note
              </Button>
            </Flex>
          ))}
        </View>
      </div>
      <div id='projects'>
        <Heading level={1}>My Projects</Heading>
        <View as='form' margin='3rem 0' onSubmit={createProject}>
          <Flex direction='row' justifyContent='center'>
            <TextField
              name='name'
              placeholder='Project Name'
              label='Project Name'
              labelHidden
              variation='quiet'
              required
            />
            <TextField
              name='description'
              placeholder='Project Description'
              label='Project Description'
              labelHidden
              variation='quiet'
              required
            />
            <View
              name='image'
              as='input'
              type='file'
              style={{ alignSelf: "end" }}
            />
            <Button type='submit' variation='primary'>
              Create Project
            </Button>
          </Flex>
        </View>
        <Heading level={2}>Current Projects</Heading>
        <View margin='3rem 0'>
          {projects.map((project) => (
            <Flex
              key={project.id || project.name}
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Text as='strong' fontWeight={700}>
                {project.name}
              </Text>
              <Text as='span'>{project.description}</Text>
              <Text as='span'>{project.link}</Text>
              {project.image && (
                <Image
                  src={project.image}
                  alt={`visual aid for ${projects.name}`}
                  style={{ width: 400 }}
                />
              )}
              <Button variation='link' onClick={() => deleteProject(project)}>
                Delete 1 note
              </Button>
            </Flex>
          ))}
        </View>
      </div>

      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(App);

{
  /* <div className='upload-container'>
<input
  type='file'
  accept='image/*'
  name='image'
  id='file'
  onChange={previewFile}
  style={{ display: "none" }}
/>
<label htmlFor='file' style={{ cursor: "pointer" }}>
  Upload Images
</label>
{imagePreview && (
  <img id='output' src={imagePreview} alt='Preview' width='200' />
)}
<button onClick={saveImgInServer}>Save Image</button>
</div> */
}
